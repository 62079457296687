export const PORTFOLIO_DOMAIN_ID = 'POMA_Portfolio';
export const PORTFOLIO_FIELDS_TO_QUERY = [
    'CreateValuationView',
    'EnableInvestment',
    'EnableValuationGroup',
    'Id',
    'IsActive',
    'LongName',
    'Name',
    'Portfolio_EnableInvestment',
    'Portfolio_EnableValuationGroup',
    'Portfolio_Type',
    'Portfolio_TypeCode',
    'PortfolioId',
    'PortfolioRegionCodes',
    'ShortName',
    'ValuationSetupAssetDefaultView',
    'ValuationSetupGroupDefaultView',
    'Portfolio_DisplayAssetValuationDifferenceCount',
    'Portfolio_IncludeDebtEquityAnalysis',
    'EnableBidEngage',
    'Portfolio_IncludeApproveProposedValuers',
    'Portfolio_IncludeApproveProposedValuersLevel2',
    'Portfolio_IncludeApproveRecommendationValuers',
    'Portfolio_IncludeApproveRecommendationValuersLevel2',
];
export const PORTFOLIO_FIELDNAME_NAME = 'ShortName';
export const PORTFOLIO_FIELDNAME_ID_AS_INCLUDED = 'Portfolio_Id';
export const PORTFOLIO_PLURAL_FIELDNAME_ID_AS_INCLUDED = 'Portfolio_Ids';
export const OPTIONAL_PORTFOLIO_FIELDNAME = 'Optional_Portfolio_Ids';
export const PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION = 'PortfolioId';
export const PORTFOLIO_INCLUDED_FIELD_PREFIX = 'Portfolio_';
export const URL_PARAMNAME_FILTER = 'filter';
export const URL_PARAMNAME_DEFAULTS = 'defaults';
