import { useQuery } from 'react-query';
import { useExecuteLiftAndSiftQuery } from '../useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
import { QueryExecuteRequestBuilder } from '../../builders/QueryExecuteRequestBuilder/QueryExecuteRequestBuilder';
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
export const REPORT_VALIDATION_KEY = 'ReportValidation';
export const useReportValidation = (domainId, filterIds, validationExpression, filterExpression, portfolioFilterExpression) => {
    const { SystemSummaryDisplayName } = useApplicationContext();
    const [executeQuery] = useExecuteLiftAndSiftQuery();
    const fullFilterExpression = new ExpressionBuilder(portfolioFilterExpression).and(filterExpression).build();
    const requestBody = new QueryExecuteRequestBuilder(domainId)
        .withAdHocListViewMembers([
        {
            scalarDisplayName: SystemSummaryDisplayName,
            scalarExpression: '',
            summaryDisplayName: SystemSummaryDisplayName,
            summaryExpression: validationExpression,
        },
    ])
        .withFilterExpression(fullFilterExpression)
        .withFilterIds(filterIds)
        .withSummaryMode()
        .build();
    const queryResult = useQuery([REPORT_VALIDATION_KEY, domainId, ...filterIds, validationExpression, filterExpression], () => {
        if (!validationExpression)
            return undefined;
        return executeQuery(requestBody);
    }, { retry: false });
    return queryResult;
};
export default useReportValidation;
