import React from 'react';
import { ReportCatalogContent as BaseReportCatalogContent } from './ReportCatalogContent.State';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
export const ReportCatalogContent = ({ domain, portfolioIds, report, onChange, currencyExchange, }) => {
    const { SystemPortfolioIdExpression, SystemPortfolioIdExpressionAlt, SystemPortfolioIdsExpression } = useApplicationContext();
    const portfolioFilterExpression = new ExpressionBuilder(`DoesContain(${SystemPortfolioIdExpression}, '${portfolioIds.map((e) => e).join(',')}')`)
        .and(`DoesContain(${SystemPortfolioIdExpressionAlt}, '${portfolioIds.map((e) => e).join(',')}')`)
        .and(`DoesContain(${SystemPortfolioIdsExpression}, '${portfolioIds.map((e) => e).join(',')}')`)
        .build(domain);
    return (React.createElement(BaseReportCatalogContent, { portfolioFilterExpression: portfolioFilterExpression, report: report, onChange: onChange, currencyExchange: currencyExchange }));
};
export default ReportCatalogContent;
