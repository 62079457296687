import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ViewSetPage } from '../../pages/ViewSetPage/ViewSetPage';
import { ReportCatalogPage } from '../../pages/ReportCatalogPage/ReportCatalogPage';
import { ETab } from '../../pages/ReportCatalogPage/ReportCatalogPage.Tabs';
import { StandardReportDetailPage } from '../../pages/StandardReportDetailPage/StandardReportDetailPage';
import { HangfirePage } from '../../pages/HangfirePage/HangfirePage';

export const Router = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/StandardReportDetails/:dId/:id" element={<StandardReportDetailPage />} />
      <Route path="/:id/:tabId/:subtabId" element={<ViewSetPage />} />
      <Route path="/:id/:tabId" element={<ViewSetPage />} />
      <Route path="/:id" element={<ViewSetPage />} />
      <Route path="/report-catalog" element={<ReportCatalogPage activeTab={ETab.ReportCatalog} />} />
      <Route path="/report-catalog/my-reports" element={<ReportCatalogPage activeTab={ETab.MyReports} />} />
      <Route path="/report-catalog/role-reports" element={<ReportCatalogPage activeTab={ETab.RoleReports} />} />
      <Route path="/reporting-hangfire" element={<HangfirePage />} />
    </Routes>
  );
};

export default Router;
