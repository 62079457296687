import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabBar } from '@samc/react-ui-core';
import {
  EditReportCellRenderer,
  EditRoleReportCellRenderer,
  GetExportReportCellRendererForPortfolioId,
  GetExportRoleReportCellRendererForPortfolioId,
  MyReportsPage,
  RoleReportsPage,
  ReportCatalogPage as ReportCatalogPageComponent,
  ReportCatalogPageHeader,
  TabTemplate,
  useApplicationContext,
} from '@samc/reports';
import { ColumnConfigurations, EditingProvider } from '@samc/react-ui-grid';
import { useNavigate } from 'react-router';

export enum ETab {
  ReportCatalog,
  MyReports,
  RoleReports,
}

interface Props {
  activeTab: ETab;
}

export const ReportCatalogPage = ({ activeTab }: Props): React.ReactElement => {
  const { SystemMyReportDomainId, SystemRoleReportDomainId } = useApplicationContext();
  const navigate = useNavigate();

  const dirtinessScope = React.useRef<HTMLDivElement>(null);

  const navigateToMyReportsTab = (): void => {
    navigate('/report-catalog/my-reports');
  };
  const navigateToRoleReportsTab = (): void => {
    navigate('/report-catalog/role-reports');
  };
  const navigateToReportCatalogTab = (): void => {
    navigate('/report-catalog');
  };

  const additionalColumns: ColumnConfigurations[] = [
    {
      index: 2,
      displayNameRule: 'Run Report',
      field: 'ExportReport',
      cellRenderer: GetExportReportCellRendererForPortfolioId(),
      gridSelector: SystemMyReportDomainId,
      width: 100,
    },
    {
      index: 3,
      displayNameRule: 'View/Edit Report Info',
      field: 'EditReport',
      cellRenderer: EditReportCellRenderer,
      gridSelector: SystemMyReportDomainId,
      width: 100,
    },
    {
      index: 2,
      displayNameRule: 'Run Report',
      field: 'ExportReport',
      cellRenderer: GetExportRoleReportCellRendererForPortfolioId(),
      gridSelector: SystemRoleReportDomainId,
      width: 100,
    },
    {
      index: 3,
      displayNameRule: 'View/Edit Report Info',
      field: 'EditReport',
      cellRenderer: EditRoleReportCellRenderer,
      gridSelector: SystemRoleReportDomainId,
      width: 100,
    },
  ];
  const location = useLocation();
  return (
    <EditingProvider
      value={{
        customColumns: additionalColumns,
      }}
    >
      <ReportCatalogPageHeader
        dirtinessScope={dirtinessScope}
        showControlBar={activeTab === ETab.MyReports || activeTab === ETab.RoleReports}
      />
      <TabTemplate
        ref={dirtinessScope}
        tabBar={
          <TabBar rounded showBorder>
            <Tab
              key="ReportCatalog"
              content="Report Catalog"
              onClick={navigateToReportCatalogTab}
              selected={activeTab === ETab.ReportCatalog}
            />
            <Tab
              key="MyReports"
              content="My Reports"
              onClick={navigateToMyReportsTab}
              selected={activeTab === ETab.MyReports}
            />
            <Tab
              key="RoleReports"
              content="Role Reports"
              onClick={navigateToRoleReportsTab}
              selected={activeTab === ETab.RoleReports}
            />
          </TabBar>
        }
        tabContent={
          <>
            {activeTab === ETab.ReportCatalog && <ReportCatalogPageComponent location={location} />}
            {activeTab === ETab.MyReports && <MyReportsPage />}
            {activeTab === ETab.RoleReports && <RoleReportsPage />}
          </>
        }
      />
    </EditingProvider>
  );
};

export default ReportCatalogPage;
