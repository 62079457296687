import React from 'react';
import { styled } from 'styletron-react';
import { ReportExport as BaseReportExport } from './ReportExport.Export';
import { ReportValidation } from '../ReportValidation/ReportValidation';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
const StyledFlexRow = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
}));
export const ReportExport = ({ currencyExchange, domainId, filterSelection, gridColumnFilterExpression, onClickLoadGrid, onExportReport, portfolioFilterExpression, reportId, validationExpression, validationText, }) => {
    const gridColumnFilterExpressionForPortfolio = React.useMemo(() => {
        return new ExpressionBuilder(portfolioFilterExpression).and(gridColumnFilterExpression).build();
    }, [portfolioFilterExpression, gridColumnFilterExpression]);
    return (React.createElement(StyledFlexRow, null,
        React.createElement(ReportValidation, { domainId: domainId, filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpression, portfolioFilterExpression: portfolioFilterExpression, validationExpression: validationExpression, validationText: validationText }, (canExportReport) => (React.createElement(BaseReportExport, { currencyExchange: currencyExchange, filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpressionForPortfolio, isExportDisabled: !canExportReport, onClickLoadGrid: onClickLoadGrid, onExportReport: onExportReport, reportId: reportId })))));
};
export default ReportExport;
