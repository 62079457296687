var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ConvertGridFilterToFilterExpression, GetFilterModelForTextFilter, GridDropdown, } from '@samc/react-ui-grid';
import { FieldLabel } from '@samc/react-ui-form';
import { styled } from 'styletron-react';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { useExecuteLiftAndSiftQuery } from '../../hooks/useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
import { ReportsExecuteRequestBuilder } from '../../builders/ReportsExecuteRequestBuilder/ReportsExecuteRequestBuilder';
import { REPORTS_KEY } from '../../hooks/useReports/useReports';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    width: '400px',
    flex: '0 0 auto',
}));
const StyledGridInputWrapper = styled('div', () => ({
    display: 'inline-block',
    flex: '1 1 300px',
}));
export const ReportPicker = ({ fields, onReportSelectionChanged, report, portfolioIds }) => {
    const { QueryClient } = useApplicationContext();
    const [executeQuery] = useExecuteLiftAndSiftQuery();
    const getOptions = React.useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        const { filter } = params;
        let filterModel = filter === null || filter === void 0 ? void 0 : filter.filterModel;
        filterModel = GetFilterModelForTextFilter(filterModel, fields);
        const filterExpression = ConvertGridFilterToFilterExpression(filterModel, fields);
        const result = yield QueryClient.fetchQuery([REPORTS_KEY, portfolioIds], () => {
            var _a, _b;
            return executeQuery(new ReportsExecuteRequestBuilder()
                .withFilterExpression(filterExpression)
                .withFilterByOptionalPortfolioIds(portfolioIds)
                .withPaging(params.offset, filter === null || filter === void 0 ? void 0 : filter.limit)
                .withSorting((((_a = filter === null || filter === void 0 ? void 0 : filter.sortModel) === null || _a === void 0 ? void 0 : _a.length) || 0) > 0 ? filter === null || filter === void 0 ? void 0 : filter.sortModel[0].sort : undefined, (((_b = filter === null || filter === void 0 ? void 0 : filter.sortModel) === null || _b === void 0 ? void 0 : _b.length) || 0) > 0 ? filter === null || filter === void 0 ? void 0 : filter.sortModel[0].colId : undefined)
                .build());
        }, { retry: false });
        return {
            totalCount: result.TotalRecords,
            options: result.Data.map((item) => ({
                id: item.primaryKey,
                displayText: item.Name,
                data: item,
            })),
        };
    }), [portfolioIds, QueryClient, executeQuery, fields]);
    const onChange = (ev) => {
        const { selectedOptions } = ev;
        if (!selectedOptions || selectedOptions.length !== 1) {
            onReportSelectionChanged === null || onReportSelectionChanged === void 0 ? void 0 : onReportSelectionChanged();
            return;
        }
        onReportSelectionChanged === null || onReportSelectionChanged === void 0 ? void 0 : onReportSelectionChanged(selectedOptions[0].data);
    };
    const onGridReady = (event) => {
        event.api.applyColumnState({
            state: [{ colId: 'Name', sort: 'asc' }],
        });
    };
    const resolveSelection = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        if (ids.length !== 1)
            return [];
        const result = yield QueryClient.fetchQuery([REPORTS_KEY, portfolioIds], () => executeQuery(new ReportsExecuteRequestBuilder()
            .withFilterByOptionalPortfolioIds(portfolioIds)
            .withFilterById(ids[0])
            .build()), { retry: false });
        return result.Data.map((item) => ({
            id: item.primaryKey,
            displayText: item.Name,
            data: item,
        }));
    });
    const selectedRecord = report ? [report.Id] : undefined;
    return (React.createElement(StyledRoot, null,
        React.createElement(FieldLabel, { labelText: "Select Report\u00A0\u00A0\u00A0" }),
        React.createElement(StyledGridInputWrapper, null,
            React.createElement(GridDropdown, { autoHeight: false, fields: fields, getOptions: getOptions, multiSelect: false, onChange: onChange, onGridReady: onGridReady, resolveSelection: resolveSelection, selectedKeys: selectedRecord, selectRowOnClick: true }))));
};
export default ReportPicker;
