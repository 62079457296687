var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FieldLabel } from '@samc/react-ui-form';
import { Picklist } from '@samc/picklist-core';
import { styled } from 'styletron-react';
import React from 'react';
import { getCombinedExpression } from '@samc/react-ui-core';
import { evaluate } from '@samc/expressions-core';
import { PORTFOLIO_FIELDNAME_ID_AS_INCLUDED, PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION, URL_PARAMNAME_DEFAULTS, } from '../../static/portfolioDomainConstants';
import { decodeBase64ToObject } from '../../static/util';
import { usePortfolios } from '../../hooks/usePortfolios/usePortfolios';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    width: '400px',
    flex: '0 0 auto',
}));
const StyledGridInputWrapper = styled('div', () => ({
    display: 'inline-block',
    flex: '1 1 300px',
}));
export const getPortfolioFromStorage = () => {
    const portfolio = localStorage.getItem(PORTFOLIO_FIELDNAME_ID_AS_INCLUDED);
    try {
        return portfolio && portfolio.length ? JSON.parse(portfolio) : null;
    }
    catch (_a) {
        return null;
    }
};
export const getFirstAvailablePortfolio = (portfolios) => portfolios && portfolios.length ? portfolios[0] : null;
export const getPortfolioWithIdElseDefault = (portfolios, id) => {
    if (!portfolios || !portfolios.length) {
        return null;
    }
    const found = portfolios.find((p) => p.Id === id);
    if (found) {
        return found;
    }
    return getFirstAvailablePortfolio(portfolios);
};
const emptyArray = [];
export const PortfolioSelector = ({ location, selectedPorfolios, setSelectedPorfolios }) => {
    const portfolioQueryResults = usePortfolios();
    const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const portfolios = portfolioQueryResults.data || emptyArray;
    const [data, setData] = React.useState();
    React.useEffect(() => {
        const result = portfolioQueryResults;
        if (result && result.data && result.data.length > 0) {
            setData(result.data);
        }
    }, [portfolioQueryResults]);
    const portfolioPicklistItem = React.useMemo(() => {
        return portfolios.map((item) => {
            return {
                id: item.Id,
                parentId: item.Name,
                longName: item.Name,
                shortName: item.ShortName,
                sortOrder: 0,
                isActive: true,
                isSystemDefined: true,
            };
        });
    }, [portfolios]);
    const onChange = React.useCallback((value) => {
        setSelectedPorfolios(value);
    }, [setSelectedPorfolios]);
    const getPortfolio = React.useCallback(() => {
        let portfolio;
        const portfolioId = params.has(PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION)
            ? params.get(PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION)
            : null;
        const defaults = params.has(URL_PARAMNAME_DEFAULTS) ? params.get(URL_PARAMNAME_DEFAULTS) : null;
        const retrieved = getPortfolioFromStorage();
        if (!defaults && portfolioId) {
            return getPortfolioWithIdElseDefault(portfolios, portfolioId);
        }
        if (defaults && defaults.length) {
            try {
                const { PortfolioId } = decodeBase64ToObject(defaults);
                if (PortfolioId && PortfolioId.length)
                    return getPortfolioWithIdElseDefault(portfolios, PortfolioId);
            }
            catch (ex) {
                return getFirstAvailablePortfolio(portfolios);
            }
        }
        if (retrieved) {
            portfolio = retrieved;
            return getPortfolioWithIdElseDefault(portfolios, portfolio.Id);
        }
        return getFirstAvailablePortfolio(portfolios);
    }, [portfolios, params]);
    React.useEffect(() => {
        if (!data)
            return;
        const portfolio = getPortfolio();
        if (portfolio &&
            selectedPorfolios.length === 0 &&
            selectedPorfolios.indexOf(portfolio.Id) === -1 &&
            typeof portfolio.Id === 'string') {
            setSelectedPorfolios([portfolio.Id]);
        }
    }, [data]);
    const getFetcher = React.useCallback((valueProperty, allItems = portfolioPicklistItem) => (_a) => __awaiter(void 0, [_a], void 0, function* ({ ids, limit, offset, sortOverride, filters }) {
        let items = allItems;
        if (ids) {
            const idSet = new Set(ids);
            items = items.filter((i) => idSet.has(i[valueProperty !== null && valueProperty !== void 0 ? valueProperty : 'id']));
        }
        if (filters && filters.length) {
            const combined = getCombinedExpression(filters);
            if (combined) {
                const mappedFilter = combined.replace(/(?<=\[)[A-Z]/g, (v) => v.toLowerCase());
                items = items.filter((i) => evaluate(i, mappedFilter, true) === true);
            }
        }
        const totalItems = items.length;
        items = items.slice(offset);
        if (limit != null)
            items = items.slice(0, limit);
        if (sortOverride) {
            const { order, orderBy } = sortOverride;
            if (orderBy) {
                const { scalarExpression } = orderBy;
                items.sort((a, b) => {
                    const aResult = evaluate(a, scalarExpression, true);
                    const bResult = evaluate(b, scalarExpression, true);
                    const multiplier = order === 'asc' ? 1 : -1;
                    if (aResult > bResult)
                        return multiplier * 1;
                    if (aResult == bResult)
                        return 0;
                    return multiplier * -1;
                });
            }
        }
        return {
            items,
            totalCount: totalItems,
        };
    }), [portfolioPicklistItem]);
    return (React.createElement(StyledRoot, null,
        React.createElement(FieldLabel, { labelText: "Select Portfolio" }),
        React.createElement(StyledGridInputWrapper, null,
            React.createElement("div", { className: "portfolio-selector" },
                React.createElement(Picklist, { fetchItems: getFetcher('id'), onChange: onChange, displayProperty: "customShortName", showFilterButton: true, valueProperty: "id", filterObject: {}, selectedKey: selectedPorfolios, showCheckboxes: true })))));
};
export default PortfolioSelector;
