var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { styled } from 'styletron-react';
import { Button, ErrorMessageType, SplitButton } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { AddMyReportPanel } from '../../molecules/AddMyReportPanel/AddMyReportPanel';
import { AddRoleReportPanel } from '../../molecules/AddRoleReportPanel/AddRoleReportPanel';
const StyledButton = styled(Button, () => ({
    flex: '0 0 auto',
}));
const StyledSplitButton = styled(SplitButton, () => ({
    flex: '0 0 auto',
}));
export const ReportExport = ({ currencyExchange, filterSelection, gridColumnFilterExpression, isExportDisabled, onClickLoadGrid, onExportReport, reportId, }) => {
    const [isReportExporting, setIsReportExporting] = React.useState(false);
    const [isPanelOpen, setPanelOpen] = React.useState(false);
    const [isRolePanelOpen, setRolePanelOpen] = React.useState(false);
    const [errorResponse, setErrorResponse] = React.useState();
    const getRequiredErrors = React.useMemo(() => errorResponse
        ? [
            {
                messages: errorResponse.messages.map((_a) => {
                    var _b;
                    var { title } = _a, params = __rest(_a, ["title"]);
                    return (Object.assign(Object.assign({}, params), { title: (_b = errorResponse.error) !== null && _b !== void 0 ? _b : title, detail: title }));
                }),
                prompts: [],
                childResponses: {},
                newIds: errorResponse.newIds,
                statusCode: 1,
                messageCount: 1,
                deletedIds: [],
                updatedIds: [],
                requestIdentifier: '',
                domainId: '',
            },
        ]
        : [], [errorResponse]);
    const closePanel = () => {
        setPanelOpen(false);
    };
    const openPanel = () => {
        setPanelOpen(true);
    };
    const closeRoleReportPanel = () => {
        setRolePanelOpen(false);
    };
    const openRoleReportPanel = () => {
        setRolePanelOpen(true);
    };
    const exportReport = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((currencyExchange === null || currencyExchange === void 0 ? void 0 : currencyExchange.IncludesCurrencyExchange) &&
            (currencyExchange.ReportPeriodStart === '' ||
                currencyExchange.ReportPeriodStart === null ||
                currencyExchange.TargetCurrency === '' ||
                currencyExchange.TargetCurrency === null ||
                currencyExchange.Source === '' ||
                currencyExchange.Source === null)) {
            const message = [];
            if (currencyExchange.TargetCurrency === '' || currencyExchange.TargetCurrency === null) {
                message.push({
                    code: 'required',
                    fields: [],
                    overridable: false,
                    title: "'Target Currency' is required",
                    type: ErrorMessageType.Error,
                });
            }
            if (currencyExchange.ReportPeriodStart === '' || currencyExchange.ReportPeriodStart === null) {
                message.push({
                    code: 'required',
                    fields: [],
                    overridable: false,
                    title: "'Start of Reporting Period' is required",
                    type: ErrorMessageType.Error,
                });
            }
            if (currencyExchange.Source === '' || currencyExchange.Source === null) {
                message.push({
                    code: 'required',
                    fields: [],
                    overridable: false,
                    title: "'Source' is required",
                    type: ErrorMessageType.Error,
                });
            }
            const requiredError = {
                status: 'FAIL',
                childResponses: {},
                messageCount: message.length,
                messages: message,
                statusCode: 1,
                prompts: [],
                deletedIds: [],
                domainId: '',
                newIds: [],
                requestIdentifier: '',
                updatedIds: [],
            };
            setErrorResponse(requiredError);
        }
        else {
            setIsReportExporting(true);
            yield onExportReport();
            setIsReportExporting(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        isReportExporting && React.createElement(Spinner, { "data-testid": "spinner-ReportExport" }),
        React.createElement(StyledSplitButton, { buttonType: "secondary", items: [{ displayText: 'Save as Role Report', onClick: openRoleReportPanel }], onClick: openPanel, text: "Save as a My Report" }),
        React.createElement(StyledButton, { buttonType: "secondary", onClick: onClickLoadGrid, text: "Load Grid" }),
        React.createElement(StyledButton, { buttonType: "primary", disabled: isExportDisabled || isReportExporting, onClick: exportReport, text: "Export" }),
        React.createElement(AddMyReportPanel, { filterExpression: gridColumnFilterExpression, filterIds: filterSelection, isOpen: isPanelOpen, onCancel: closePanel, onSubmitAndClose: closePanel, reportId: reportId, currencyExchange: currencyExchange }),
        React.createElement(AddRoleReportPanel, { filterExpression: gridColumnFilterExpression, filterIds: filterSelection, isOpen: isRolePanelOpen, onCancel: closeRoleReportPanel, onSubmitAndClose: closeRoleReportPanel, reportId: reportId, currencyExchange: currencyExchange }),
        React.createElement(TaskResponsePopup, { responses: getRequiredErrors, requests: [], visible: !!errorResponse, setVisible: () => {
                setErrorResponse(undefined);
            } })));
};
export default ReportExport;
