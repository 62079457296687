import React from 'react';
import { styled } from 'styletron-react';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    height: '100%',
}));
export const PickerAndContentTemplate = ({ content, picker, portfolioSelector }) => {
    return (React.createElement(StyledRoot, null,
        portfolioSelector,
        picker,
        content));
};
export default PickerAndContentTemplate;
