import React from 'react';
import { styled } from 'styletron-react';
import { ETab, ReportCatalogPage as BaseReportCatalogPage } from './ReportCatalogPage.Tabs';

/* istanbul ignore next */
const StyledRoot = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

interface Props {
  activeTab: ETab;
}

export const ReportCatalogPage = ({ activeTab }: Props): React.ReactElement => {
  return (
    <StyledRoot>
      <BaseReportCatalogPage activeTab={activeTab} />
    </StyledRoot>
  );
};

export default ReportCatalogPage;
