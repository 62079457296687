import { styled } from 'styletron-react';
import { getButtonTextColor, getThemeButtonBorderColor, getThemeButtonColor, } from '@samc/react-ui-theme';
export const StyledButton = styled('button', (props) => ({
    height: '25px',
    paddingTop: '2px',
    paddingRight: '15px',
    paddingBottom: '2px',
    paddingLeft: '15px',
    borderLeftWidth: '1px',
    borderBottomWidth: '1px',
    borderRightWidth: '1px',
    borderTopWidth: '1px',
    borderStyle: 'solid',
    borderColor: getThemeButtonBorderColor(props.theme, props.buttonType || 'primary', props.disabled),
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
    borderTopRightRadius: '2px',
    fontSize: '14px',
    textAlign: 'center',
    color: getButtonTextColor(props.theme, props.buttonType || 'primary', props.disabled),
    backgroundColor: getThemeButtonColor(props.theme, props.buttonType || 'primary', props.disabled),
    backgroundSize: 'cover',
    cursor: props.disabled ? 'default' : 'pointer',
    ':hover': {
        backgroundColor: getThemeButtonColor(props.theme, props.buttonType || 'primary', props.disabled, true),
    },
}));
export default StyledButton;
