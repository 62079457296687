import * as React from 'react';
import { useRequestBase } from '@samc/react-ui-request';
import { getPortfolios } from '../../ReportApi/api/Requests';
import { ApiContext } from '../../ReportApi/Context/useApiContext';
import { CacheTime } from '../../static/CacheTime';
export const PORTFOLIOS_KEY = 'portfolios';
export const usePortfolios = () => {
    var _a;
    const api = React.useContext(ApiContext);
    const staleTime = CacheTime.portfolios;
    const response = useRequestBase({
        key: PORTFOLIOS_KEY,
        query: getPortfolios,
        requestContext: ApiContext,
        params: [api.LiftSiftApi, api.AggregatorApi, api.requestInit],
        options: {
            staleTime,
        },
        extraKeys: [JSON.stringify((_a = api.requestInit) === null || _a === void 0 ? void 0 : _a.headers)],
    });
    return response;
};
