var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { SectionHeader } from '@samc/react-ui-form';
import { HorizontalRule } from '../HorizontalRule/HorizontalRule';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';
import { ReportExport } from '../ReportExport/ReportExport';
import { ExpressionBuilder } from '../../builders/ExpressionBuilder/ExpressionBuilder';
export const ReportFilter = ({ currencyExchange, filterSelection, gridColumnFilterExpression, onClickLoadGrid, portfolioFilterExpression, report, sortOrder, sortOrderBy, }) => {
    const { useGenerateReportMutation } = useApplicationContext();
    const [generateReportMutation] = useGenerateReportMutation();
    const exportReport = () => __awaiter(void 0, void 0, void 0, function* () {
        const filterExpression = new ExpressionBuilder(portfolioFilterExpression).and(gridColumnFilterExpression).build();
        try {
            yield generateReportMutation.mutateAsync({
                domainId: report.DomainId,
                viewId: report.ListViewId,
                reportId: report.Id,
                filterExpression,
                templateId: report.ReportTemplateStrataId,
                sortOrder,
                sortOrderBy,
                filterIds: filterSelection,
                source: currencyExchange !== undefined ? currencyExchange === null || currencyExchange === void 0 ? void 0 : currencyExchange.Source : '',
                reportPeriodStart: currencyExchange !== undefined ? currencyExchange.ReportPeriodStart : '',
                targetCurrency: currencyExchange !== undefined ? currencyExchange.TargetCurrency : '',
                includesCurrencyExchange: currencyExchange !== undefined ? currencyExchange.IncludesCurrencyExchange : false,
            });
        }
        catch (e) {
            console.error(e);
        }
    });
    return (React.createElement("div", null,
        React.createElement(SectionHeader, { title: "Filter & Export the Report" }),
        React.createElement(HorizontalRule, null),
        React.createElement(ReportExport, { currencyExchange: currencyExchange, domainId: report.DomainId, filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpression, onClickLoadGrid: onClickLoadGrid, onExportReport: exportReport, portfolioFilterExpression: portfolioFilterExpression, reportId: report.Id, validationExpression: report.ValidationExpression, validationText: report.ValidationText })));
};
export default ReportFilter;
