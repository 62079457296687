import React from 'react';
import { styled } from 'styletron-react';
import { ReportInfo } from '../../atoms/ReportInfo/ReportInfo';
import { ReportFilter } from '../../atoms/ReportFilter/ReportFilter';
import { ReportFilterGrid } from '../../atoms/ReportFilterGrid/ReportFilterGrid';
const StyledRoot = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    flex: '1 1 auto',
}));
export const ReportCatalogContent = ({ portfolioFilterExpression, report, onChange, currencyExchange, }) => {
    const [gridColumnFilterExpression, setGridColumnFilterExpression] = React.useState();
    const initialFilterIds = report.FilterId ? [report.FilterId] : [];
    const [filterSelection, setFilterSelection] = React.useState(initialFilterIds);
    const [sortOrder, setSortOrder] = React.useState();
    const [sortOrderBy, setSortOrderBy] = React.useState();
    const [canLoadGrid, setCanLoadGrid] = React.useState(false);
    const onClickLoadGrid = () => {
        setCanLoadGrid(true);
    };
    const updateSortOrderState = (order, orderBy) => {
        setSortOrder(order);
        setSortOrderBy(orderBy);
    };
    const afterFetchData = () => {
        setCanLoadGrid(false);
    };
    return (React.createElement(StyledRoot, null,
        React.createElement(ReportInfo, { report: report, onChange: onChange }),
        React.createElement(ReportFilter, { currencyExchange: currencyExchange, filterSelection: filterSelection, gridColumnFilterExpression: gridColumnFilterExpression, onClickLoadGrid: onClickLoadGrid, portfolioFilterExpression: portfolioFilterExpression, report: report, sortOrder: sortOrder, sortOrderBy: sortOrderBy }),
        React.createElement(ReportFilterGrid, { afterFetchData: afterFetchData, canLoadGrid: canLoadGrid, filterSelection: filterSelection, onFilterSelectionChanged: setFilterSelection, onGridColumnFilterExpressionChange: setGridColumnFilterExpression, onGridColumnSortChange: updateSortOrderState, portfolioFilterExpression: portfolioFilterExpression, report: report })));
};
export default ReportCatalogContent;
