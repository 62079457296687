/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { loadTheme, MessageBar, MessageBarType } from '@fluentui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router as CustomRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BrowserHistory, HashHistory } from 'history';
import { StrataProvider } from '@samc/filestorage-react';
import 'react-toastify/dist/ReactToastify.css';
import '@samc/vmsnext-querybuilder/dist/esm/index.css';
import {
  ToastMessageContainer,
  PromptContextProvider,
  LayerWrapper,
  WysiwygLicenseProvider,
} from '@samc/react-ui-core';
import { HistoryProvider, HeaderContextProvider } from '@samc/react-ui-history';
import { globals, LSThemeWrapper } from '@samc/vmsnext-querybuilder';
import { AuthorizationWrapper, useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { useClientConfiguration } from '@samc/single-spa-client-configuration';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { defaultTheme, ThemeProvider } from '@samc/react-ui-theme';
import { Router } from './templates/Router/Router';
import { RootAppContext } from './root.component.AppContext';

// setup theme for lift and sift
loadTheme({
  defaultFontStyle: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: '12px',
  },
  palette: {
    themePrimary: '#214D88',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#858585',
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff',
  },
});

export interface SingleSpaProps {
  // eslint-disable-next-line react/no-unused-prop-types
  styletronEngine: Styletron;
  browserHistory: BrowserHistory;
  hashHistory: HashHistory;
}

const queryClient = new QueryClient();

export const Root = (props: SingleSpaProps): React.ReactElement => {
  const { styletronEngine, browserHistory, hashHistory } = props;
  const [gracePeriodActive, setGracePeriodActive] = React.useState(true);
  React.useEffect(() => {
    const timer = setTimeout(() => setGracePeriodActive(false), 5000);
    return (): void => clearTimeout(timer);
  }, []);

  const tenantState = useTenantState();
  const tenantName = tenantState?.name;

  const [location, setLocation] = React.useState(hashHistory.location);
  React.useEffect(() => {
    return hashHistory.listen((l) => setLocation(l.location));
  }, [hashHistory]);

  const authState = useUserAuthenticationState();
  const isUserAuthenticated = React.useMemo(() => !!authState?.isAuthenticated, [authState]);
  const token = authState?.accessToken?.value;

  const { result: clientConfiguration } = useClientConfiguration();

  if (clientConfiguration === undefined)
    return gracePeriodActive ? (
      <></>
    ) : (
      <MessageBar messageBarType={MessageBarType.error}>Could not load authorization configuration</MessageBar>
    );

  if (!isUserAuthenticated)
    return gracePeriodActive ? (
      <></>
    ) : (
      <MessageBar messageBarType={MessageBarType.error}>
        User is unauthorized - Please log out and back in again
      </MessageBar>
    );

  if (!tenantName)
    return gracePeriodActive ? (
      <></>
    ) : (
      <MessageBar messageBarType={MessageBarType.error}>
        No client selected - Please select a client and try again
      </MessageBar>
    );

  const headers = {
    Authorization: `Bearer ${token}`,
    'X-Tenant': tenantName,
  };

  return (
    <AgGridLicenseProvider license={clientConfiguration.agGridLicense}>
      <WysiwygLicenseProvider value={clientConfiguration.tinyMceLicense}>
        <AuthorizationWrapper
          config={{
            ...clientConfiguration,
            defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
          }}
          jwt={token}
        >
          <StyletronProvider value={styletronEngine} debugAfterHydration>
            <LSThemeWrapper>
              <ThemeProvider style={{ width: '100%', height: '100%' }} value={defaultTheme}>
                <LayerWrapper>
                  <HistoryProvider value={{ browserHistory, hashHistory }}>
                    <PromptContextProvider>
                      <HeaderContextProvider>
                        <DndProvider backend={HTML5Backend}>
                          <CustomRouter location={location} navigator={hashHistory}>
                            <QueryClientProvider client={queryClient}>
                              <StrataProvider>
                                <RootAppContext
                                  requestInit={{ headers }}
                                  tenantIdentifier={tenantState?.identifier}
                                  tenantName={tenantName}
                                >
                                  <>
                                    <Router />
                                    <ToastMessageContainer
                                      className="single-spa-reports"
                                      position="top-center"
                                      draggable
                                      pauseOnHover
                                      pauseOnFocusLoss
                                      autoClose={4000}
                                    />
                                    {/* Additional toast container to catch L&S messages that don't use the default RUI containerId */}
                                    <ToastMessageContainer
                                      className="single-spa-screen-config"
                                      containerId={globals.__TOAST_CONTAINER_ID}
                                      draggable
                                      pauseOnHover
                                      pauseOnFocusLoss
                                      autoClose={4000}
                                      // Hide duplicate close button from L&S toasts
                                      closeButton={false}
                                      // Hide duplicate icon from L&S toasts
                                      icon={false}
                                    />
                                  </>
                                </RootAppContext>
                              </StrataProvider>
                            </QueryClientProvider>
                          </CustomRouter>
                        </DndProvider>
                      </HeaderContextProvider>
                    </PromptContextProvider>
                  </HistoryProvider>
                </LayerWrapper>
              </ThemeProvider>
            </LSThemeWrapper>
          </StyletronProvider>
        </AuthorizationWrapper>
      </WysiwygLicenseProvider>
    </AgGridLicenseProvider>
  );
};

export default Root;
