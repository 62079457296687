import { Modal, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import { SitusHeader } from '../Header/SitusHeader/SitusHeader';
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        overflow: 'none',
    },
});
export const ModalPopup = (props) => {
    const { children, isOpen, headerText, onDismiss, onSubmit, confirmationButtonDisable, rejectionButtonText, confirmationButtonText, } = props;
    return (React.createElement(Modal, { isOpen: isOpen, isBlocking: true, onDismiss: onDismiss, containerClassName: contentStyles.container, styles: { main: { width: '40vw', height: '20vw' } } },
        React.createElement(SitusHeader, { title: headerText, rejectionButtonText: rejectionButtonText, onRejectionButtonClicked: onDismiss, confirmationButtonText: confirmationButtonText, onConfirmationButtonClicked: onSubmit, confirmationButtonDisable: confirmationButtonDisable }),
        React.createElement(React.Fragment, null, children)));
};
export default ModalPopup;
